import React from "react";
import PageTagging from "components/common/page-tagging/page-tagging";
import ImageHeader from "components/common/image-header";
import PurpleSection from "components/purple-section/purple-section";
import ContentSection from "components/common/content-section";
import TextTitle from "components/common/text-title";

const AboutUs = () => {
  return (
    <>
      <ImageHeader imgSrc={`/images/tanks-2.jpg`} title="About Us" />
      <PurpleSection text="Safety always comes first at Fast Lane Traffic." />
      <ContentSection>
        <div className="w-100 text-center px-5">
          <TextTitle>
            <div className="title px-5">
              <h2>About Us</h2>
            </div>
          </TextTitle>
        </div>

        <div className="row mt-4">
          <div className="col-md-5">
            <h5 className="mt-4 mb-3">
              Fast Lane Traffic Pty Ltd was established in 2021 and is a proud
              South Australian owned and operated Traffic Management provider
              for various Clients working over many sectors. Our Team comprises
              of experienced personnel who continually deliver innovative
              solutions to traffic management challenges, ensuring safe
              delivery, quality completion and value for money outcomes for our
              Clients.
            </h5>
            <p>
              Safety always comes first at Fast Lane Traffic. Our experienced
              Operations team work closely with our Clients to ensure full
              compliance with all Traffic Management industry safety codes and
              standards as well as WHS requirements on all worksites.
            </p>
          </div>
          <div className="col-md-7">
            <img
              src="/images/about-us-main.jpg"
              className="mt-4 shadow"
              alt=""
            />
          </div>
        </div>
      </ContentSection>

      <ContentSection bgClass="sw-bg-std-grey py-5 d-flex flex-row justify-content-center features">
        <div className="w-100 text-center px-5">
          <TextTitle>
            <div className="title px-5">
              <h2>Our Team</h2>
            </div>
          </TextTitle>
        </div>
        <div className="row mt-4 text-center">
          <div className="col-md-6">
            <h5 className="mt-4">Stefan Webb</h5>
            <p>
              Stefan has accumulated a wealth of experience over 15 years in the
              traffic management industry. Commencing ‘on the road’ as a Traffic
              Controller and moving throughout various Operational roles has
              given him the foundation to lead the SA business.Stefan has a
              demonstrable track record in managing multiple contractor
              resources to execute services under strong governance frameworks.
              Specialising in Traffic Management, Risk Management and WHS
              compliance, Stefan has worked for both Clients and providers in
              ensuring management plan outcomes are accomplished.
            </p>
          </div>
          <div className="col-md-6">
            <h5 className="mt-4">Jay Byrne</h5>
            <p>
              Jay commenced his career as a traffic controller back in 2012
              putting in the hard yards on the road learning the craft. In 2018
              Jay began his venture into the Operations realm of traffic
              management where he learnt planning, scheduling and project
              management. Over recent years Jay has ventured into Event Traffic
              Management and has worked on some of SA's most iconic Events.
            </p>
          </div>
        </div>
      </ContentSection>
      <ContentSection bgClass="py-5 d-flex flex-row justify-content-center features">
        <div className="w-100 text-center px-5">
          <TextTitle>
            <div className="title px-5">
              <h2>Our Process</h2>
            </div>
          </TextTitle>
        </div>
        <div className="row mt-4 text-center">
          <div className="col-md-6">
            <h5 className="mt-5">Consultation</h5>
            <p>
              We gain an in-depth understanding of the project requirements and
              recommend a solution.
            </p>
          </div>
          <div className="col-md-6">
            <h5 className="mt-5">Cost Estimation</h5>
            <p>We will generate a cost estimate based on our consultation.</p>
          </div>
          <div className="col-md-6">
            <h5 className="mt-5">Planning</h5>
            <p>
              We liaise with you to plan and schedule the works, ensuring there
              are no delays on-site. Then we will submit plans to necessary
              authorities and arrange permits.
            </p>
          </div>
          <div className="col-md-6">
            <h5 className="mt-5">Completion</h5>
            <p>
              Our Traffic Controllers will implement the plan onsite and will
              complete all relevant documents pertaining to your work onsite
              then our Administration team will submit an invoice for payment.
            </p>
          </div>
        </div>
        <div className="row mt-5 text-center">
          <div className="col-md-12">
            <h5>
              We are proud members of the Traffic Management Association of
              Australia
            </h5>
          </div>
          <div className="col-md-3 rounded mx-auto mt-5">
            <a
              href="https://tmaa.asn.au/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://solvable.azureedge.net/fast-lane/tmaa-logo.jpg"
                className=""
                alt=""
              />
            </a>
          </div>
        </div>
      </ContentSection>

      <PageTagging
        title="About Us | Traffic Management Industry Leader South Australia"
        description=""
      />
    </>
  );
};

export default AboutUs;
