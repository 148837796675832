import React from "react";
import ContentSection from "components/common/content-section";
import "./partners-header.scss";

const ParnersHeader = () => {
  return (
    <ContentSection bgClass="partners-header">
      <h2 className="text-white text-center mt-2">Our Partners</h2>
    </ContentSection>
  );
};

export default ParnersHeader;
