import React from "react";
import PageTagging from "components/common/page-tagging/page-tagging";
import ImageHeader from "components/common/image-header";
import Features from "./components/features";
import ParnersHeader from "./components/partners-header";
import Partner from "./components/partner";
import "./home.scss";
import PurpleSection from "components/purple-section/purple-section";

const Home = () => {
  return (
    <div className="home">
      <ImageHeader imgSrc={`/images/utes.jpg`} isHero>
        <div className="text-white d-flex flex-column justify-content-around align-items-center image-jumbotron">
          <div>
            <h1 className="text-uppercase font-weight-bold main-text">
              Fast Lane Traffic
            </h1>
          </div>

          <div>
            <h1 className="sub-text text-uppercase">
              Safety always comes first
            </h1>
          </div>
        </div>
      </ImageHeader>

      <PurpleSection
        text="Get a free estimate today!"
        linkUrl="/estimate/"
        linkTitle="Get a free estimate"
        isBold
      />

      <Features />

      <ParnersHeader />

      <Partner />

      <PageTagging
        title="Fast Lane Traffic | South Australia's Industry Leader | Traffic Management"
        description="South Australia's Leading Provider of Traffic Management Services. Fast Lane Traffic are a wholly Australian-owned company that specialises in providing Traffic Management solutions and related services."
      />
    </div>
  );
};

export default Home;
