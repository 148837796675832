import React from "react";
import ImageHeader from "components/common/image-header";
import PurpleSection from "components/purple-section/purple-section";
import ContentSection from "components/common/content-section";
import TextTitle from "components/common/text-title";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <>
      <ImageHeader imgSrc={`/images/services.jpg`} title="Services" />
      <PurpleSection text="Safety always comes first at Fast Lane Traffic." />

      <ContentSection>
        <div className="w-100 text-center px-5">
          <TextTitle>
            <div className="title px-5">
              <h2>Our Services</h2>
            </div>
          </TextTitle>
        </div>
        <div className="row mt-3">
          <div className="col-md-6 text-center">
            <h4 className="mt-5">Workzone Traffic Management</h4>
            <img
              src="/images/traffic-management.webp"
              className="mt-4 mb-4 shadow"
              alt=""
            />
            <p className="font-weight-bold">
              Fast Lane Traffic providing Traffic Management services to all
              areas of South Australia.
            </p>
            <p>
              Fast Lane Traffic has fully accredited and trained traffic
              controllers with fully stocked vehicles ready to go 24/7. We
              operate in all areas of South Australia providing traffic
              management on all types of roads. Our staff work to and are
              compliant with National & SA Standards, Austroads Guides and all
              other relevant regulations.
            </p>
            <p className="mb-4">
              We understand that safe Traffic Management is required at all
              hours of the day and night, so we operate 24 hours a day, 7 days a
              week, 365 days a year across Adelaide and regional South
              Australia. Our phones are staffed 24/7 by our skilled team.
            </p>
            <div>
              <Link
                to="/contact-us/"
                className="sw-btn-primary btn btn-secondary"
              >
                Enquire Now
              </Link>
            </div>
          </div>
          <div className="col-md-6 text-center">
            <h4 className="mt-5">
              Traffic Planning, DIT and Council Permit Liaison
            </h4>
            <img
              src="/images/planning.webp"
              className="mt-4 mb-4 shadow"
              alt=""
            />
            <p className="font-weight-bold">
              Fast Lane Traffic providing Traffic Management services to all
              areas of South Australia.
            </p>
            <p>
              At Fast Lane Traffic we believe that the basis to exceptional
              execution on the road is having a quality plan to implement. We
              provide the highest quality Traffic Guidance Schemes (TGS) and
              design the planning around your specifications. We have
              experienced drafters ready to tailor a TGS for your Specific job
              requirements no matter how big or small.
            </p>
            <p className="mb-4">
              Our Operations staff will contact all necessary stakeholders
              including government departments, public transport operators,
              emergency services and any other relevant body that requires
              notification of your work. We aim to make this process as seamless
              as possible.
            </p>
            <div>
              <Link
                to="/contact-us/"
                className="sw-btn-primary btn btn-secondary"
              >
                Enquire Now
              </Link>
            </div>
          </div>
          <div className="col-md-6 text-center">
            <h4 className="mt-5">Event Traffic Management</h4>
            <img src="/images/event.jpg" className="mt-4 mb-4 shadow" alt="" />
            <p className="font-weight-bold">
              Fast Lane Traffic providing Traffic Management services to all
              areas of South Australia.
            </p>
            <p className="mb-4">
              Fast Lane Traffic's Operations Team has had many years experience
              developing and implementing Event Management Plans and have worked
              on some of SA's most iconic events. We can provide all of your
              event traffic management needs, both big & small that will ensure
              your event runs smoothly and on time so that all participants,
              stakeholders and the general public remain safe.
            </p>
            <p className="mb-5">
              Our dedicated and skilled Events Team will provide a seamless
              journey from planning your event through to the delivery on the
              day. The key to a successful event occurs in the early stages of
              planning by bringing all stakeholders to the table, establishing
              the method and delivering with safety at the forefront.
            </p>
            <div>
              <Link
                to="/contact-us/"
                className="sw-btn-primary btn btn-secondary"
              >
                Enquire Now
              </Link>
            </div>
          </div>
          <div className="col-md-6">
            <h4 className="mt-5 text-center">Signage & Equipment Hire</h4>
            <img
              src="/images/fastlane-1.jpg"
              className="mt-4 mb-4 shadow"
              alt=""
            />
            <p className="font-weight-bold text-center">
              Fast Lane Traffic providing Traffic Management services to all
              areas of South Australia.
            </p>
            <p className="mb-4 text-center">
              At Fast Lane Traffic we understand that not all worksites will
              require traffic controllers on site all of the time. If your site
              requires just the basics, or 'a few' signs, we are happy to supply
              the required signage on a short term or long term hire basis. Fast
              Lane Traffic is able to source equipment that you may require for
              your work site:
            </p>
            <ul className="mb-4">
              <li>VMS Boards & Arrow Boards</li>
              <li>Traffic Lights (portable or trailer)</li>
              <li>Porta Boom</li>
              <li>Temporary Fencing</li>
              <li>Lighting Towers</li>
              <li>Barrier Systems</li>
              <li>And more...</li>
            </ul>
            <div className="text-center">
              <Link
                to="/contact-us/"
                className="sw-btn-primary btn btn-secondary"
              >
                Enquire Now
              </Link>
            </div>
          </div>
        </div>
      </ContentSection>
    </>
  );
};

export default Services;
