import React from "react";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import ContentSection from "./../components/common/content-section";
import PurpleSection from "components/purple-section/purple-section";
import ImageHeader from "components/common/image-header";
import { corePublicPages } from "./public-content-core";
import TextTitle from "components/common/text-title";
import { Link } from "react-router-dom";

export const publicPages = [
  ...corePublicPages,
  {
    pageName: "careers",
    title: "Careers | Join the team | Fast Lane Traffic",
    description:
      "Fast Lane Traffic is a leader in the Traffic Management industry in South Australia and is always on the look out for new members to join our team.",
    noContentSection: true,
    content: (
      <>
        <ImageHeader imgSrc={`/images/careers.jpg`} title="Careers" />
        <PurpleSection text="If you&rsquo;re interested in working for one of South Australia&rsquo;s leading Traffic Management companies, you have come to the right place." />
        <ContentSection>
          <div className="w-100 text-center px-5">
            <TextTitle>
              <div className="title px-5">
                <h2>Join Our Team</h2>
              </div>
            </TextTitle>
          </div>
          <div className="row mt-5">
            <div className="col-md-5">
              <h4 className="text-center primary-text">
                Safety always comes first at Fast Lane Traffic.
              </h4>
              <h5 className="mt-4 text-center">
                At Fast Lane Traffic we pride ourselves on being able to employ
                local South Australians and to be able to offer our staff
                training and development to advance their careers within our
                industry. If we can provide a stable income and a livelihood for
                our staff and their families then we are well on our way to
                making our great State even better.
              </h5>
              <h5 className="mt-5">We Offer;</h5>
              <ul>
                <li>Flexible shifts</li>
                <li>Weekly pay cycle</li>
                <li>
                  Training and Development opportunities & commitment to ongoing
                  team development
                </li>
                <li>Systemised safety and compliance processes</li>
                <li>And much more&hellip;</li>
              </ul>
            </div>
            <div className="col-md-7">
              <img src="/images/careers-main.jpg" className="shadow" alt="" />
            </div>
          </div>
        </ContentSection>
        <ContentSection bgClass="sw-bg-std-grey ">
          <div className="w-100 text-center px-5">
            <TextTitle>
              <div className="title px-5">
                <h2 className="mb-4">Current Opportunities</h2>
                <h4>Fast Lane Traffic are currently seeking the following;</h4>
              </div>
            </TextTitle>
          </div>
          <div className="text-center mt-5">
            <h4 className="mt-4">
              Traffic Control Team Leaders (Minimum 6 Months Experience)
            </h4>
            <p>
              We are seeking Traffic Controllers with proven experience to join
              our team in South Australia. All relevant licences and tickets are
              required.
            </p>
            <a
              href="https://fastlane.staffd.me/applicants/apply-now/traffic-controller"
              target="_blank"
              rel="noopener noreferrer"
              className="sw-btn-primary btn btn-secondary mt-2 mb-4"
            >
              Apply Now
            </a>
            <h4 className="mt-4">Traffic Controllers (No Experience)</h4>
            <p>
              We are seeking Traffic Controllers with all relevant licences and
              tickets. No experience required.
            </p>
            <a
              href="https://fastlane.staffd.me/applicants/apply-now/traffic-controller"
              target="_blank"
              rel="noopener noreferrer"
              className="sw-btn-primary btn btn-secondary mt-2 mb-4"
            >
              Apply Now
            </a>
          </div>
        </ContentSection>
        <ContentSection>
          <div className="w-100 text-center px-5">
            <TextTitle>
              <div className="title px-5">
                <h2>Contact us today to find out more.</h2>
              </div>
            </TextTitle>
          </div>
          <div className="text-center mt-4 mb-4">
            <Link
              to="/contact-us/"
              className="sw-btn-primary btn btn-secondary"
            >
              Enquire Now
            </Link>
          </div>
        </ContentSection>
      </>
    ),
  },
  {
    pageName: "gallery",
    title: "Photo Gallery | Fast Lane Traffic",
    description:
      "Fast Lane Traffic is the largest Traffic Management company in South Australia and is always on the look out for new members to join our team.",
    noContentSection: true,
    content: (
      <>
        {/* <ImageHeader imgSrc={`/images/gallery.jpg`} title="Photo Gallery" /> */}
        <PurpleSection text="You will see us around all parts of South Australia. Here are a few of our memories." />
        <ContentSection>
          <div className="w-100 text-center px-5">
            <TextTitle>
              <div className="title px-5">
                <h2>Photo Gallery</h2>
              </div>
            </TextTitle>
          </div>
          <div className="row mt-5 text-center">
            <div className="col-md-6 mb-3">
              <img src="/images/gallery/utes.jpg" className="shadow" alt="" />
            </div>
            <div className="col-md-6 mb-3">
              <img src="/images/gallery/event.jpg" className="shadow" alt="" />
            </div>
            <div className="col-md-6 mb-3">
              <img src="/images/gallery/tanks.jpg" className="shadow" alt="" />
            </div>
            <div className="col-md-6 mb-3">
              <img
                src="/images/gallery/high-tea-ccf.jpg"
                className="shadow"
                alt=""
              />
            </div>
            <div className="col-md-12 mb-3">
              <img
                src="/images/gallery/fastlane-15.jpg"
                className="shadow"
                alt=""
              />
            </div>
            <div className="col-md-6 mb-3">
              <img
                src="/images/gallery/fastlane-3.jpg"
                className="shadow"
                alt=""
              />
            </div>

            <div className="col-md-6 mb-3">
              <img
                src="/images/gallery/fastlane-6.jpg"
                className="shadow"
                alt=""
              />
            </div>
            <div className="col-md-6 mb-3">
              <img
                src="/images/gallery/fastlane-5.jpg"
                className="shadow"
                alt=""
              />
            </div>
            <div className="col-md-6 mb-3">
              <img
                src="/images/gallery/traffic-management.webp"
                className="shadow"
                alt=""
              />
            </div>
            <div className="col-md-6 mb-3">
              <img
                src="/images/gallery/fastlane-2.jpg"
                className="shadow"
                alt=""
              />
            </div>
            <div className="col-md-6 mb-3">
              <img
                src="/images/gallery/fastlane-8.jpg"
                className="shadow"
                alt=""
              />
            </div>
            <div className="col-md-6 mb-3">
              <img
                src="/images/gallery/fastlane-4.jpg"
                className="shadow"
                alt=""
              />
            </div>
            <div className="col-md-6 mb-3">
              <img
                src="/images/gallery/fastlane-7.jpg"
                className="shadow"
                alt=""
              />
            </div>
            <div className="col-md-6 mb-3">
              <img
                src="/images/gallery/fastlane-11.jpg"
                className="shadow"
                alt=""
              />
            </div>
            <div className="col-md-6 mb-3">
              <img
                src="/images/gallery/fastlane-9.jpg"
                className="shadow"
                alt=""
              />
            </div>
            <div className="col-md-6 mb-3">
              <img
                src="/images/gallery/fastlane-14.jpg"
                className="shadow"
                alt=""
              />
            </div>
            <div className="col-md-6 mb-3">
              <img
                src="/images/gallery/fastlane-10.jpg"
                className="shadow"
                alt=""
              />
            </div>
            <div className="col-md-6 mb-3">
              <img
                src="/images/gallery/fastlane-16.jpg"
                className="shadow"
                alt=""
              />
            </div>
            <div className="col-md-6 mb-3">
              <img
                src="/images/gallery/fastlane-17.jpg"
                className="shadow"
                alt=""
              />
            </div>
            <div className="col-md-6 mb-3">
              <img
                src="/images/gallery/fastlane-18.jpg"
                className="shadow"
                alt=""
              />
            </div>
            <div className="col-md-6 mb-3">
              <img
                src="/images/gallery/fastlane-19.jpg"
                className="shadow"
                alt=""
              />
            </div>
            <div className="col-md-6 mb-3">
              <img
                src="/images/gallery/fastlane-20.jpg"
                className="shadow"
                alt=""
              />
            </div>
            <div className="col-md-6 mb-3">
              <img
                src="/images/gallery/fastlane-21.jpg"
                className="shadow"
                alt=""
              />
            </div>
            <div className="col-md-6 mb-3">
              <img
                src="/images/gallery/fastlane-22.jpg"
                className="shadow"
                alt=""
              />
            </div>
          </div>
        </ContentSection>
      </>
    ),
  },
  {
    pageName: "new-page",
    title: "New Page",
    description: "",
    content: (
      <>
        <h1>New Page</h1>
      </>
    ),
  },
  {
    pageName: "new-page-2",
    title: "New Page",
    description: "",
    noContentSection: true,
    content: (
      <>
        <ContentSection>
          <h1>Section 1</h1>
        </ContentSection>
        <ContentSection bgClass="sw-bg-std-grey ">
          <h1>Section 2</h1>
        </ContentSection>
        <ContentSection>
          <h1>Section 3</h1>
        </ContentSection>
      </>
    ),
  },
];
