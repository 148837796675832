import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import EnhancedContentSection from "components/common/enhanced-content-section";
import classnames from "classnames";
import "./contact.scss";
import ContactUsForm from "./components/contact-us";
import QuoteForm from "./components/quote";

const ContactUs = () => {
  const [activeTab, setActiveTab] = useState(1);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/estimate/") setActiveTab(2);
  }, [location]);

  return (
    <>
      <EnhancedContentSection
        isBlocking={false}
        showPageTitle={true}
        pageTitle="For all things traffic, call or email us today."
      >
        <p className="w-100 text-center my-5"></p>

        <div className="contact">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={`${classnames({
                  active: activeTab === 1,
                })} nav-link`}
                onClick={() => toggle(1)}
              >
                Contact Us
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${classnames({
                  active: activeTab === 2,
                })} nav-link`}
                onClick={() => toggle(2)}
              >
                Get an Estimate
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={1}>
              <ContactUsForm />
            </TabPane>
            <TabPane tabId={2}>
              <QuoteForm />
            </TabPane>
          </TabContent>
        </div>
      </EnhancedContentSection>
    </>
  );
};

export default ContactUs;
