export const navLinks = [
  {
    title: "Home",
    link: "/",
  },
  {
    title: "About Us",
    link: "/about/",
  },
  {
    title: "Services",
    link: "/services/",
  },
  {
    title: "Careers",
    link: "/careers/",
  },
  {
    title: "Gallery",
    link: "/gallery/",
  },
  {
    title: "Contact Us",
    link: "/contact-us/",
  },
];
