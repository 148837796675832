import React from "react";
import ButtonFx from "components/common/button-fx";
import { Navbar, NavbarBrand, Container, Row, Col } from "reactstrap";
import "./nav-bar.scss";
import { useHistory } from "react-router";
import { navLinks } from "constants/navigation";
import PriorityNavBar from "./priority-nav";

const NavBar = () => {
  const history = useHistory();
  const navigate = (path) => history.push(path);

  return (
    <Navbar
      light={false}
      dark={false}
      expand="md"
      className="navbar-default sticky-top sticky-nav navbar-bg d-flex flex-column"
    >
      <div className="top-header w-100 d-flex">
        <Container fluid className="container-style py-2 d-none d-lg-block">
          <Row className="d-flex justify-content-between align-items-center">
            <Col xs="6" className="text-white">
              <Row className="d-flex justify-content-start">
                <div className="mr-4">
                  <a href="tel:+61 416 964 553">Call Us! 0416 964 553</a>
                </div>
                <div>
                  <Row>
                    <Col xs="12" md="6" xl="2">
                      <div className="d-flex flex-row social-wrapper">
                        <div className="social-container flex justify-content-center align-items-center">
                          <a
                            href="https://www.facebook.com/www.fastlanetraffic.com.au/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="fab fa-facebook-f social-icon" />
                          </a>
                        </div>
                        <div className="social-container">
                          <a
                            href="https://www.linkedin.com/company/fast-lane-traffic/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="fab fa-linkedin social-icon" />
                          </a>
                        </div>
                        <div className="social-container">
                          <a
                            href="https://www.instagram.com/fastlanetraffic/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="fab fa-instagram social-icon" />
                          </a>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Row>
            </Col>
            <Col xs="6" className="d-flex justify-content-end">
              <ButtonFx
                className="sw-btn-contact"
                onClick={() => navigate("/contact-us/")}
              >
                <i className="fas fa-envelope mr-2" /> Contact Us
              </ButtonFx>
              <ButtonFx
                className="sw-btn-contact"
                onClick={() => navigate("/estimate/")}
              >
                <i className="fas fa-calculator mr-2" />
                Get an Estimate
              </ButtonFx>
            </Col>
          </Row>
        </Container>
      </div>

      <Container
        fluid
        className="container-style d-flex justify-content-between"
      >
        <NavbarBrand href="/">
          <img
            src="/images/fast-lane-logo.png"
            alt="Fast Lane Traffic Pty Ltd"
            className="img-fluid"
          />
        </NavbarBrand>

        <div className="priority-nav">
          <PriorityNavBar links={navLinks} />
        </div>
      </Container>
    </Navbar>
  );
};

export default NavBar;
