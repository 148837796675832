import React from "react";
import ContentSection from "components/common/content-section";
import TextTitle from "components/common/text-title";
import { Row, Col } from "reactstrap";
import CardItem from "./components/card-item";
import "./features.scss";
import { Link } from "react-router-dom";

const Features = () => {
  return (
    <ContentSection bgClass="py-5 d-flex flex-row justify-content-center features">
      <div className="w-100 text-center px-5">
        <div>
          <TextTitle>
            <div className="title px-5">
              <h2>WE ARE AVAILABLE 24/7</h2>
            </div>
          </TextTitle>

          <p className="mt-5 p-details">
            Fast Lane Traffic Pty Ltd was established in 2021 and is a proud
            South Australian owned and operated Traffic Management provider for
            various Clients working over many sectors. Our Team comprises of
            experienced personnel who continually deliver innovative solutions
            to traffic management challenges, ensuring safe delivery, quality
            completion and value for money outcomes for our Clients.
          </p>
        </div>
      </div>
      <div className="w-100 text-center px-5 mt-5">
        <TextTitle>
          <div className="title px-5 text-uppercase">
            <h2>Our Services</h2>
          </div>
        </TextTitle>
      </div>
      <Row className="mt-5 px-2 cards">
        <Col lg="3" md="6" xs="12" className="mb-4 item">
          <CardItem>
            <img
              src="/images/traffic-management.webp"
              className="shadow"
              alt=""
            />
            <div className="title">
              <h3>Work-Zone Traffic Management</h3>
            </div>

            <p className="p-details">
              Fast Lane Traffic has fully accredited and trained traffic
              controllers with fully stocked vehicles ready to go 24/7. We
              operate in all areas of South Australia providing traffic
              management on all types of roads.
            </p>
            <div>
              <Link to="/services/" className="sw-btn-primary btn">
                Learn More
              </Link>
            </div>
          </CardItem>
        </Col>
        <Col lg="3" md="6" xs="12" className="mb-4 item">
          <CardItem>
            <img src="/images/planning.webp" className="shadow" alt="" />

            <div className="title">
              <h3>Traffic Planning Services</h3>
            </div>

            <p className="p-details">
              At Fast Lane Traffic we believe that the basis to exceptional
              execution on the road is having a quality plan to implement. We
              provide the highest quality Traffic Guidance Schemes and design
              the planning around your specifications.
            </p>
            <div>
              <Link to="/services/" className="sw-btn-primary btn">
                Learn More
              </Link>
            </div>
          </CardItem>
        </Col>
        <Col lg="3" md="6" xs="12" className="mb-4 item">
          <CardItem>
            <img src="/images/event.jpg" className="shadow" alt="" />

            <div className="title">
              <h3>Event Management Services</h3>
            </div>

            <p className="p-details">
              Fast Lane Traffic's Team has many years experience in developing
              and implementing Event Management Plans and have worked on some of
              SA's most iconic events. We can provide all of your event traffic
              management needs.
            </p>
            <div>
              <Link to="/services/" className="sw-btn-primary btn">
                Learn More
              </Link>
            </div>
          </CardItem>
        </Col>

        <Col lg="3" md="6" xs="12" className="mb-4 item">
          <CardItem>
            <img src="/images/fastlane-1.jpg" className="shadow" alt="" />

            <div className="title">
              <h3>Signage & Equipment Hire</h3>
            </div>

            <p className="p-details">
              At Fast Lane Traffic we understand that not all worksites will
              require traffic controllers on site all of the time. If your site
              requires just the basics, or 'a few' signs, we are happy to supply
              the required signage on a short term or long term hire basis.
            </p>
            <div>
              <Link to="/services/" className="sw-btn-primary btn">
                Learn More
              </Link>
            </div>
          </CardItem>
        </Col>
      </Row>
      <div className="w-100 text-center px-5 mt-5">
        <TextTitle>
          <div className="title px-5 mt-5 text-uppercase">
            <h2>Safety always comes first at Fast Lane Traffic.</h2>
          </div>
        </TextTitle>
      </div>
      <p className="mt-5 p-details text-center px-5">
        Here is the first in a series of videos to promote road safety and
        driving safely through roadworks brought to you by Fast Lane Traffic.
        There is not enough education and awareness for drivers on how to safely
        navigate their way past a worksite, even our learner drivers have little
        to no instruction! Hopefully this message can be pushed through social
        media channels in the hope that drivers slow down around our vulnerable
        workers.
      </p>
      <p className="p-details text-center px-5">
        <strong>Please slow down when you are driving past road works.</strong>
      </p>
      <div
        className="mt-5"
        style={{ maxWidth: "800px", marginLeft: "auto", marginRight: "auto" }}
      >
        <div className="video-wrapper">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/1YVM4oB84VM?si=Xtxf354XP76He7gb"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </ContentSection>
  );
};

export default Features;
